@font-face {
  font-family: 'HudsonNY';
  src: url('../../../assets/fonts/2F0E63_0_0.eot');
  src: url('../../../assets/fonts/2F0E63_0_0.eot?#iefix') format('embedded-opentype'),
       url('../../../assets/fonts/2F0E63_0_0.woff2') format('woff2'),
       url('../../../assets/fonts/2F0E63_0_0.woff') format('woff'),
       url('../../../assets/fonts/2F0E63_0_0.ttf') format('truetype');
}

@font-face {
  font-family: 'Avenir Next';
  src: url("../../../assets/fonts/e9167238-3b3f-4813-a04a-a384394eed42.eot?#iefix");
  src:
    url("../../../assets/fonts/e9167238-3b3f-4813-a04a-a384394eed42.eot?#iefix") format('eot'),
    url("../../../assets/fonts/1e9892c0-6927-4412-9874-1b82801ba47a.woff") format('woff'),
    url("../../../assets/fonts/46cf1067-688d-4aab-b0f7-bd942af6efd8.ttf") format('truetype'),
    url("../../../assets/fonts/52a192b1-bea5-4b48-879f-107f009b666f.svg#52a192b1-bea5-4b48-879f-107f009b666f") format('svg')
}

@font-face {
  font-family: 'Avenir Next Thin';
  src: url("../../../assets/fonts/42c793cf-df5a-4e07-bf3e-37de54df02b6.eot?#iefix");
  src:
    url("../../../assets/fonts/42c793cf-df5a-4e07-bf3e-37de54df02b6.eot?#iefix") format('eot'),
    url("../../../assets/fonts/c6c8e4be-17eb-4475-bbfc-bb485ffde766.woff") format('woff'),
    url("../../../assets/fonts/87a872d0-5af3-4231-a77c-37f41ec74662.ttf") format('truetype'),
    url("../../../assets/fonts/91464696-717b-4eaa-adbd-0dfc5e5fd782.svg#91464696-717b-4eaa-adbd-0dfc5e5fd782") format('svg')
}

@font-face {
  font-family: 'Avenir Next Demi';
  src: url("../../../assets/fonts/12d643f2-3899-49d5-a85b-ff430f5fad15.eot?#iefix");
  src:
    url("../../../assets/fonts/12d643f2-3899-49d5-a85b-ff430f5fad15.eot?#iefix") format('eot'),
    url("../../../assets/fonts/91b50bbb-9aa1-4d54-9159-ec6f19d14a7c.woff") format('woff'),
    url("../../../assets/fonts/a0f4c2f9-8a42-4786-ad00-fce42b57b148.ttf") format('truetype'),
    url("../../../assets/fonts/99affa9a-a5e9-4559-bd07-20cf0071852d.svg#99affa9a-a5e9-4559-bd07-20cf0071852d") format('svg')
}
