@font-face {
  font-family: 'Permanent Marker';
  src: url(../assets/fonts/PermanentMarker-Regular.ttf);
}

@font-face {
  font-family: 'Roboto-Regular';
  src: url('../assets/fonts/Roboto-Regular.ttf');
}

@font-face {
  font-family: 'Roboto-Medium';
  src: url('../assets/fonts/Roboto-Medium.ttf');
}

body {
  margin: unset;
}

.app {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.hidden {
  display: none;
}
