@import '../../styles/variables/variables';

.app-header {
  position: relative;
  width: 100%;
  height: $header-height;
  z-index: 3;

  .logo {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: ceil(calc($logo-height / -2));
    margin-left: ceil(calc($logo-width / -2));
    background: url(../../assets/images/logo.svg);
    width: $logo-width;
    height: $logo-height;
    text-indent: 100%;
    overflow: hidden;
  }

  .back {
    position: absolute;
    right: 40px;
    top: 50%;
    margin-top: -10px;
    padding-right: 3px;
    padding-left: 3px;
    line-height: 20px !important;
    text-transform: uppercase;
    text-decoration: unset;
    font: 9pt $body;
    color: black;
    transition: right .25s, font .25s, color .25s;
    outline: none;

    &:hover, &:active {
      color: $highlight-color;
    }

    &:focus {
      box-shadow: 0 0 0 2px $custom-dark-gray;
      border-radius: 2px;
    }
  }

  @media (max-width: 768px) {
    .back {
      right: 20px;
    }
  }

  @media (max-width: 576px) {
    .back {
      display: none;
    }
  }
}